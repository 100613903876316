import React from 'react';
import AsciiArt from '../Component/AsciiArt';
import './Home.css';

const cow1 = `
          (__)
          (oo)
  /-------\\/
 / |     ||
*  ||----||
   ^^    ^^`;

const cow2 = `
          (__)
          (oo)
  /-------\\/
 / |     ||
*  ||W---||
   ^^    ^^`;

const cow3 = `
          (__)
          (SS)
  /-------\\/
 / |     ||
*  ||----||
   ^^    ^^`;

const cow4 = `
          (__)
          (@@)
  /-------\\/
 / |     ||
*  ||----||
   ^^    ^^`;

const cow5 = `
          (__)
          (OO)
  /-------\\/
 / |     ||
*  ||w---||
   ^^    ^^`;

const farm = `
             +&-
                     _.-^-._    .--.
                  .-'   _   '-. |__|
                 /     |_|     \\|  |
                /               \\  |
               /|     _____     |\\ |
                |    |==|==|    |  |
|---|---|---|---|    |--|--|    |  |
|---|---|---|---|    |==|==|    |  |
^^^^^^^^^^^^^^^^^^^^^^^^^|               |  |^^^^^^^^^`;

const tractor = `





      /\\  ,-,---,
       //\\\\/|_|___|  
         ,__//  \\\\|/\`\`\\ |--/-\\  _
          \\_/    {( () ) {(===t||
............... \\__/\`\`\`\`\\_/`;

const buyButton = `/---------\\
|   BUY   |
\\---------/`;

const xButton = `/-------\\
|   X   |
\\-------/`;

const telegramButton = `/---------\\
| TELEGRAM |
\\---------/`;

const cow1Text = "Cow";
const cow2Text = "Cow with milk";
const cow3Text = "Solana cow";
const cow4Text = "Degen cow";
const cow5Text = "Cow who took ayahuesca";

const Home = () => {
  return (
    <div className="home_container">
      <div className='home_title_links_container'>
        <div className='home_title'>ascii cow.</div>
        <div className='home_links_container'>
          <a className='buy_button_link' target="_blank" rel="noopener noreferrer" href='https://pump.fun/'>
            <AsciiArt art={buyButton} fontSize={"1.5rem"}/>
          </a>
          <a className='buy_button_link' target="_blank" rel="noopener noreferrer" href='https://pump.fun/'>
            <AsciiArt art={xButton} fontSize={"1.5rem"}/>
          </a>
          <a className='buy_button_link' target="_blank" rel="noopener noreferrer" href='https://t.me/asciicow'>
            <AsciiArt art={telegramButton} fontSize={"1.5rem"}/>
          </a>
        </div>
      </div>
      <div className="cows_container">
        <div className="cow_container">
          <AsciiArt art={cow1} fontSize={"1.7rem"}/>
          <div className='cow_container_text'>{cow1Text}</div>
        </div>
        <div className="cow_container">
          <AsciiArt art={cow2} fontSize={"1.7rem"}/>
          <div className='cow_container_text'>{cow2Text}</div>
        </div>
        <div className="cow_container">
          <AsciiArt art={cow3} fontSize={"1.7rem"}/>
          <div className='cow_container_text'>{cow3Text}</div>
        </div>
        <div className="cow_container">
          <AsciiArt art={cow4} fontSize={"1.7rem"}/>
          <div className='cow_container_text'>{cow4Text}</div>
        </div>
        <div className="cow_container">
          <AsciiArt art={cow5} fontSize={"1.7rem"}/>
          <div className='cow_container_text'>{cow5Text}</div>
        </div>
      </div>
      <div className='bottom_farm_container'>
        <AsciiArt art={tractor} fontSize={"1.3em"} />
        <AsciiArt art={farm} fontSize={"1.3em"} />
      </div>
    </div>
  );
};

export default Home;